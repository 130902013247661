var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function Progress(theme) {
    var rootStyle = function (ownerState) {
        var bufferVariant = ownerState.variant === 'buffer';
        var defaultStyle = __assign({ borderRadius: 4, '& .MuiLinearProgress-bar': {
                borderRadius: 4,
            } }, (bufferVariant && {
            backgroundColor: 'transparent',
        }));
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && {
            backgroundColor: alpha(theme.palette[color].main, 0.24),
        }))); });
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle], false);
    };
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
